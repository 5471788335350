import { CarouselCustomNavigation } from "../../components/carousel/carousel"

import "./style.home.css"

export default function Home() {
    return (
        <div className="mx-auto max-w-screen-xl px-4 py-2">
            <CarouselCustomNavigation />
            <div className="flex justify-center text-center items-center flex-col">
                <h1 className="my-2.5">
                    Olá! Tudo bem? Seja bem-vindo(a) ao Universo da Personalização!
                </h1>
                <h4 className="rounded-lg colorFund w-4/5 md:w-3/5 my-2.5">
                    <strong>
                        O UP é uma loja com o intuito de oferecer diversidade em um mesmo lugar. E, o melhor, montar os produtos do seu jeito!
                        <br />
                        Isso mesmo, nosso objetivo é criar itens personalizados e especiais para cada cliente, afinal cada um tem seus próprios gostos.
                        <br />
                        Fique a vontade para explorar nosso site! Criamos ele para que sua experiência seja incrível!
                    </strong>
                </h4>
                <h4 className="text-start w-4/5 md:w-3/5 my-2.5">
                    <strong>Missão: </strong>Entregar ao cliente produtos e serviços personalizados. <br />
                    <strong>Visão: </strong>Realizar o desejo das pessoas. <br />
                    <strong>Valores: </strong>Qualidade, carinho, atenção, dedicação e ética.
                </h4>
            </div>
        </div>
    )
}