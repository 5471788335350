import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react";

import ImageCopoSlim from "../../img/produtos/copo_slim/copo_slim.webp"

export function PageCopoSlim() {
    return (
        <div className="flex justify-center">
            <Card className="containerCard w-96 mt-8 mb-8">
                <CardHeader shadow={false} floated={false} className="h-96">
                    <img
                        src={ImageCopoSlim}
                        alt="Imagem do Copo Slim"
                        className="h-full w-full"
                    />
                </CardHeader>
                <CardBody>
                    <div className="mb-2 flex items-center justify-between">
                        <Typography color="blue-gray" className="font-mediu">
                            Copo Slim Cor sólida
                        </Typography>
                        <Typography color="blue-gray" className="font-medium">
                            R$7.00
                        </Typography>
                    </div>

                    <div className="mb-2 flex items-center justify-between">
                        <Typography color="blue-gray" className="font-mediu">
                            Copo Sim Degradê ou Bicolor
                        </Typography>
                        <Typography color="blue-gray" className="font-medium">
                            R$9.00
                        </Typography>
                    </div>

                    <br />

                    <div className="mb-2 flex items-center justify-between">
                        <Typography color="blue-gray" className="font-mediu">
                            Pedido minímo de 15 unidades
                        </Typography>
                    </div>
                </CardBody>
                <CardFooter className="pt-0">
                    <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                        <Button
                            ripple={false}
                            fullWidth={true}
                            className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                        >
                            COMPRAR
                        </Button>
                    </a>
                </CardFooter>
            </Card>
        </div>
    )
}