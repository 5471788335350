import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NavbarWithMegaMenu } from './components/header/navbar/navbar'

import { FooterWithLogo } from './components/folter/folter'

import Home from "./pages/home/home";

import { PageCanecaCeramica } from "./pages/canecaCeramica/canecaCeramica";
import { PageCanecaPolimero } from "./pages/canecaPolimero/canecaPolimero"
import { PageCanecaChopp } from "./pages/canecaChopp/canecaChopp";

import { PageTacaGin } from "./pages/tacaGin/tacaGin"
import { PageTacaChampanhe } from "./pages/tacaChampanhe/tacaChampanhe"
import { PageTacaCerveja } from "./pages/tacaCerveja/tacaCerveja";

import { PageCopoLongDrink } from "./pages/copoLongDrink/copoLongDrink";
import { PageCopoWhisky } from "./pages/copoWhisky/copoWhisky"
import { PageCopoTwister } from "./pages/copoTwister/copoTwister";
import { PageCopoChopp } from "./pages/copoChopp/copoChopp";
import { PageCopoSlim } from "./pages/copoSlim/copoSlim";

import { PageGarrafinha } from "./pages/garrafinha/garrafinha";
import { PageCamisa } from "./pages/camisa/camisa";
import { PageAzulejo } from "./pages/azulejo/azulejo";

import { PagePlanetaLivros } from "./pages/planetaLivros/planetaLivros";
import { PageArtesanato } from "./pages/artesanato/artesanato";

export default function Rotas() {
    return (
        <BrowserRouter>
            <NavbarWithMegaMenu />
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/caneca-ceramica" element={<PageCanecaCeramica />} />
                <Route path="/caneca-polimero" element={<PageCanecaPolimero />} />
                <Route path="/caneca-chopp-jateada" element={<PageCanecaChopp />} />

                <Route path="/taca-gin" element={<PageTacaGin />} />
                <Route path="/taca-champanhe" element={<PageTacaChampanhe />} />
                <Route path="/taca-cerveja" element={<PageTacaCerveja />} />


                <Route path="/copo-long-drink" element={<PageCopoLongDrink />} />
                <Route path="/copo-whisky" element={<PageCopoWhisky />} />
                <Route path="/copo-twister" element={<PageCopoTwister />} />
                <Route path="/copo-chopp" element={<PageCopoChopp />} />
                <Route path="/copo-slim" element={<PageCopoSlim />} />

                <Route path="/garrafinha" element={<PageGarrafinha />} />
                <Route path="/camisa" element={<PageCamisa />} />
                <Route path="/azulejo" element={<PageAzulejo />} />

                <Route path="/planeta-livros" element={<PagePlanetaLivros />} />
                <Route path="/artesanato" element={<PageArtesanato />} />

            </Routes>
            <FooterWithLogo />
        </BrowserRouter>
    )
}