import React from "react";
import {
    Navbar,
    Collapse,
    Typography,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import {
    ChevronDownIcon,
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";

import { Link } from "react-router-dom"
import Logo from "../../../img/header/logo/logoBranco.png"
import "./style.navbar.css"

const navListMenuItems = [
    {
        title: "Caneca de Cerâmica",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/4552/4552196.png",
        path: "/caneca-ceramica"
    },
    {
        title: "Caneca de Polímero",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/4552/4552196.png",
        path: "/caneca-polimero"
    },
    {
        title: "Caneca de Chopp Jateada",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/761/761856.png",
        path: "/caneca-chopp-jateada"
    },
    {
        title: "Taça de Gin",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/3329/3329192.png",
        path: "/taca-gin"
    },
    {
        title: "Taça de Champanhe",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/590/590928.png",
        path: "/taca-champanhe"
    },
    {
        title: "Taça de Cerveja",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/3656/3656042.png",
        path: "/taca-cerveja"
    },
    {
        title: "Copo Long Drink",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/7021/7021441.png",
        path: "/copo-long-drink"
    },
    {
        title: "Copo de Whisky",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/590/590930.png",
        path: "/copo-whisky"
    },
    {
        title: "Copo Twister",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/83/83431.png",
        path: "/copo-twister"
    },
    {
        title: "Chopp",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/3156/3156569.png",
        path: "/copo-chopp"
    },
    {
        title: "Copo Slim",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/7070/7070016.png",
        path: "/copo-slim"
    },
    {
        title: "Garrafinha",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/4295/4295735.png",
        path: "/garrafinha"
    },
    {
        title: "Camiseta",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/3746/3746120.png",
        path: "/camisa"
    },
    {
        title: "Azulejo",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/3235/3235083.png",
        path: "/azulejo"
    },
    {
        title: "Planeta Livros",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/1600/1600770.png",
        path: "/planeta-livros"
    },
    {
        title: "Artesanato",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/4839/4839846.png ",
        path: "/artesanato"
    }
];

function NavListMenu() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

    const closeProductMenu = () => {
        if (window.innerWidth < 768) {
            setIsMobileMenuOpen(false);
        }
        setIsMenuOpen(false);
        console.log("Product menu closed");
    };

    const renderItems = navListMenuItems.map(
        ({ imageSrc, title, path }, key) => (
            <Link to={path} key={key} onClick={closeProductMenu}>
                <MenuItem className="flex items-center gap-3 rounded-lg">
                    <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
                        <img src={imageSrc} alt={imageSrc} width={'25px'} />
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="text-white lg:text-gray-700 flex items-center text-sm font-bold"
                        >
                            {title}
                        </Typography>
                    </div>
                </MenuItem>
                <hr className="border-blue-gray-50" />
            </Link>
        ),
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
                allowHover={true}
            >
                <MenuHandler>
                    <Typography as="div" variant="small" className="font-medium">
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 font-medium text-white"
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            Produtos
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""
                                    }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""
                                    }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className="grid grid-cols-4 gap-y-2 outline-none outline-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}

function NavList() {
    return (
        <List className=" mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
            <Typography
                as="a"
                href="#"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <Link to='/'>
                    <ListItem className="flex items-center gap-2 py-2 pr-4 text-white">
                        Home
                    </ListItem>
                </Link>
            </Typography>
            <NavListMenu />
        </List>
    );
}

export function NavbarWithMegaMenu() {
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false),
        );
    }, []);

    return (
        <Navbar className="containerHeader mx-auto max-w-screen-xl px-4 py-2">
            <div className=" flex items-center justify-between text-blue-gray-900">
                <Link to="/">
                    <Typography
                        as="a"
                        href="#"
                        variant="h6"
                        className="mr-4 cursor-pointer py-1.5 lg:ml-2"
                    >
                        <img src={Logo} alt="" width={"105px"} />
                    </Typography>
                </Link>
                <div className="hidden lg:block">
                    <NavList />
                </div>

                <div className="hidden gap-2 lg:flex">
                    <a href="https://www.instagram.com/universodapersonalizacao/?next=%2F" target='blank'>
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                            alt="Instagram"
                            width={"40px"}
                        ></img>
                    </a>

                    <a
                        className="whats"
                        target='blank'
                        href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0"
                    >
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/733/733585.png"
                            alt="WhatsApp"
                            width={"40px"}
                        ></img>
                    </a>

                    <a href="https://www.facebook.com/universodapersonalizacao" target='blank'>
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                            alt="FaceBook"
                            width={"40px"}
                        ></img>
                    </a>
                </div>

                <IconButton
                    variant="text"
                    color="blue-gray"
                    className="lg:hidden"
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                        <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                    ) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                    )}
                </IconButton>

            </div>

            <Collapse open={openNav}>
                <NavList />
                <div className="flex w-full flex-nowrap items-center justify-center gap-10 lg:hidden">
                    <a href="https://www.instagram.com/universodapersonalizacao/?next=%2F" target='blank'>
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                            alt="Instagram"
                            width={"40px"}
                        ></img>
                    </a>

                    <a
                        className="whats"
                        target='blank'
                        href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0"
                    >
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/733/733585.png"
                            alt="WhatsApp"
                            width={"40px"}
                        ></img>
                    </a>

                    <a href="https://www.facebook.com/universodapersonalizacao" target='blank'>
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                            alt="FaceBook"
                            width={"40px"}
                        ></img>
                    </a>
                </div>
            </Collapse>
        </Navbar>
    );
}