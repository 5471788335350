import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Carousel
} from "@material-tailwind/react";

import ImageArtesanato1 from "../../img/produtos/artesanato/Artesanato_1.webp"
import ImageArtesanato2 from "../../img/produtos/artesanato/Artesanato_2.webp"
import ImageArtesanato3 from "../../img/produtos/artesanato/Artesanato_3.webp"
import ImageArtesanato4 from "../../img/produtos/artesanato/Artesanato_4.webp"
import ImageArtesanato5 from "../../img/produtos/artesanato/Artesanato_5.webp"
import ImageArtesanato6 from "../../img/produtos/artesanato/Artesanato_6.webp"

export function PageArtesanato() {
    return (
        <div className="flex justify-center items-center flex-col lg:flex-row">
            <Card className="containerCard w-96 mt-8 mb-8">
                <CardHeader shadow={false} floated={false} className="h-96">
                    <Carousel
                        className="rounded-xl"
                        navigation={({ setActiveIndex, activeIndex, length }) => (
                            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                {new Array(length).fill("").map((_, i) => (
                                    <span
                                        key={i}
                                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                            }`}
                                        onClick={() => setActiveIndex(i)}
                                    />
                                ))}
                            </div>
                        )}
                    >
                        <img
                            src={ImageArtesanato1}
                            alt=""
                            className="h-full w-full object-cover"
                        />
                        <img
                            src={ImageArtesanato2}
                            alt=""
                            className="h-full w-full object-cover"
                        />
                        <img
                            src={ImageArtesanato3}
                            alt=""
                            className="h-full w-full object-cover"
                        />
                    </Carousel>
                </CardHeader>
                <CardBody>
                    <div className="mb-2 flex items-center justify-between">
                        <Typography color="blue-gray" className="font-mediu">
                            Guirlanda de Páscoa
                        </Typography>
                        <Typography color="blue-gray" className="font-medium">
                            R$140.00
                        </Typography>
                    </div>
                </CardBody>
                <CardFooter className="pt-0">
                    <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                        <Button
                            ripple={false}
                            fullWidth={true}
                            className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                        >
                            COMPRAR
                        </Button>
                    </a>
                </CardFooter>
            </Card>

            <Card className="containerCard w-96 mt-8 mb-8">
                <CardHeader shadow={false} floated={false} className="h-96">
                    <Carousel
                        className="rounded-xl"
                        navigation={({ setActiveIndex, activeIndex, length }) => (
                            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                {new Array(length).fill("").map((_, i) => (
                                    <span
                                        key={i}
                                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                            }`}
                                        onClick={() => setActiveIndex(i)}
                                    />
                                ))}
                            </div>
                        )}
                    >
                        <img
                            src={ImageArtesanato4}
                            alt=""
                            className="h-full w-full object-cover"
                        />
                        <img
                            src={ImageArtesanato5}
                            alt=""
                            className="h-full w-full object-cover"
                        />
                        <img
                            src={ImageArtesanato6}
                            alt=""
                            className="h-full w-full object-cover"
                        />
                    </Carousel>
                </CardHeader>
                <CardBody>
                    <div className="mb-2 flex items-center justify-between">
                        <Typography color="blue-gray" className="font-mediu">
                            Bolas de isopor Páscoa
                        </Typography>
                        <Typography color="blue-gray" className="font-medium">
                            R$30.00
                        </Typography>
                    </div>
                </CardBody>
                <CardFooter className="pt-0">
                    <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                        <Button
                            ripple={false}
                            fullWidth={true}
                            className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                        >
                            COMPRAR
                        </Button>
                    </a>
                </CardFooter>
            </Card>
        </div>
    );
}