import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom"
import Logo from "../../img/footer/logo/logoBranco.png"

import "./style.footer.css"

export function FooterWithLogo() {
    return (
        <footer className="containerFooter mx-auto max-w-screen-xl px-4 py-2">
            <div className="containerHeader flex flex-col lg:flex-row items-center justify-center md:justify-evenly bg-white text-center">
                <Link to="/">
                    <img src={Logo} alt="logo-ct" width={"115px"} />
                </Link>
                <ul className="flex flex-col lg:flex-row items-center justify-center gap-y-2 md:gap-x-8">

                    <li className="mt-2 lg:mt-0 mb-4 lg:mb-0">
                        <Typography
                            href="#"
                            color="blue-gray"
                            className="font-normal transition-colors text-white"
                        >
                            Horario de Funcionamento: <br />
                            Segunda á Sexta | 8:00 ás 20:00
                        </Typography>
                    </li>

                    <li className="flex mb-4 lg:mb-0">
                        <Typography as="a" href="#" className="opacity-80 transition-opacity hover:opacity-100">
                            <a href="https://www.instagram.com/universodapersonalizacao/?next=%2F" target='blank'>
                                <img
                                    src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                                    alt="Instagram"
                                    width={"25px"}
                                ></img>
                            </a>
                        </Typography>

                        <Typography as="a" href="#" className="opacity-80 transition-opacity hover:opacity-100 mx-2.5">
                            <a
                                target='blank'
                                href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0"
                            >
                                <img
                                    src="https://cdn-icons-png.flaticon.com/512/733/733585.png"
                                    alt="WhatsApp"
                                    width={"25PX"}
                                ></img>
                            </a>
                        </Typography>

                        <Typography as="a" href="#" className="opacity-80 transition-opacity hover:opacity-100">
                            <a href="https://www.facebook.com/universodapersonalizacao" target='blank'>
                                <img
                                    src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                                    alt="FaceBook"
                                    width={"25px"}
                                ></img>
                            </a>
                        </Typography>
                    </li>

                    <li>
                        <Typography
                            as="a"
                            href="#"
                            color="blue-gray"
                            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
                        >
                            <iframe
                                className="map rounded-lg"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d922.8302141737639!2d-49.10438325562816!3d-22.303701999364904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94bf661fcb919983%3A0x2c17d73a774c90a9!2sAlameda%20Di%C3%B3genes%2C%204-20%20-%20Parque%20Santa%20Edwiges%2C%20Bauru%20-%20SP%2C%2017067-650!5e0!3m2!1spt-BR!2sbr!4v1683576976133!5m2!1spt-BR!2sbr"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </Typography>
                    </li>
                </ul>
            </div>
        </footer>
    );
}