import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Collapse
} from "@material-tailwind/react";

import ImageKitHarryPotter from "../../img/produtos/harry_poter/kit-harry-potter.webp"

import ImageVarinha from "../../img/produtos/harry_poter/varinha.jpg"
import ImagePocoes from "../../img/produtos/harry_poter/pocao.webp"
import ImageAdesivo from "../../img/produtos/harry_poter/adesivo.webp"
import ImageCarta from "../../img/produtos/harry_poter/carta.webp"
import ImageMapa from "../../img/produtos/harry_poter/mapa-maroto.webp"
import ImageMarca from "../../img/produtos/harry_poter/marcapagina.jpeg"


import "./style.css"

export function PagePlanetaLivros() {

    const [open, setOpen] = React.useState(false);

    const toggleOpen = () => setOpen((cur) => !cur);

    return (
        <div className="flex justify-center">
            <div className="flex justify-center items-center flex-col">
                <div>
                    <Card className="containerCard w-96 mt-8 mb-8">
                        <CardHeader shadow={false} floated={false} className="h-96">
                            <img
                                src={ImageKitHarryPotter}
                                alt="Imagem da Taça de Cerveja"
                                className="h-full w-full "
                            />
                        </CardHeader>
                        <CardBody>
                            <div className="mb-2 flex items-center justify-between">
                                <Typography color="blue-gray" className="font-mediu">
                                    Kit Harry Potter
                                </Typography>

                                <Typography color="blue-gray" className="font-mediu">
                                    R$140,00
                                </Typography>
                            </div>
                        </CardBody>
                        <CardFooter className="pt-0">

                            <Button
                                ripple={false}
                                fullWidth={true}
                                onClick={toggleOpen}
                                className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                            >
                                INFORMAÇÕES SOBRE O KIT
                            </Button>
                            <Collapse open={open}>
                                <Typography color="blue-gray" className="font-mediu">
                                    <br />

                                    <a href="#varinha">
                                        • Varinha
                                    </a>

                                    <br />

                                    <a href="#pocoes">
                                        • 4 poções
                                    </a>

                                    <br />

                                    <a href="#adesivo">
                                        • Cartela de adesivo
                                    </a>

                                    <br />

                                    <a href="#carta">
                                        • Carta com passagem e ticket nightbus
                                    </a>

                                    <br />

                                    <a href="#mapa">
                                        • Mapa do maroto
                                    </a>

                                    <br />

                                    <a href="#marca">
                                        • Marca página
                                    </a>

                                    <br />

                                    <a href="/caneca-ceramica">
                                        • Caneca
                                    </a>

                                    <br />

                                    + 2 brindes
                                </Typography>
                            </Collapse>

                            <br />

                            <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                                <Button
                                    ripple={false}
                                    fullWidth={true}
                                    className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                >
                                    COMPRAR
                                </Button>
                            </a>

                        </CardFooter>
                    </Card>
                </div>

                <div className="flex flex-col lg:flex-row">
                    <Card className="containerCard w-96 mt-8 mb-8" id="varinha">
                        <CardHeader shadow={false} floated={false} className="h-96">
                            <img
                                src={ImageVarinha}
                                alt="Imagem da Taça de Cerveja"
                                className="image-css h-full w-full"
                            />
                        </CardHeader>
                        <CardBody>
                            <div className="mb-2 flex items-center justify-between">
                                <Typography color="blue-gray" className="font-mediu">
                                    Varinha
                                </Typography>

                                <Typography color="blue-gray" className="font-mediu">
                                    R$25,00
                                </Typography>
                            </div>
                        </CardBody>
                        <CardFooter className="pt-0">

                            <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                                <Button
                                    ripple={false}
                                    fullWidth={true}
                                    className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                >
                                    COMPRAR
                                </Button>
                            </a>

                        </CardFooter>
                    </Card>

                    <Card className="containerCard w-96 mt-8 mb-8" id="pocoes">
                        <CardHeader shadow={false} floated={false} className="h-96">
                            <img
                                src={ImagePocoes}
                                alt="Imagem da Taça de Cerveja"
                                className="h-full w-full "
                            />
                        </CardHeader>
                        <CardBody>
                            <div className="mb-2 flex items-center justify-between">
                                <Typography color="blue-gray" className="font-mediu">
                                    4 Poções
                                </Typography>

                                <Typography color="blue-gray" className="font-mediu">
                                    R$30,00
                                </Typography>
                            </div>
                        </CardBody>
                        <CardFooter className="pt-0">
                            <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                                <Button
                                    ripple={false}
                                    fullWidth={true}
                                    className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                >
                                    COMPRAR
                                </Button>
                            </a>

                            <br />
                        </CardFooter>
                    </Card>
                </div>

                <div className="flex flex-col lg:flex-row">
                    <Card className="containerCard w-96 mt-8 mb-8" id="adesivo">
                        <CardHeader shadow={false} floated={false} className="h-96">
                            <img
                                src={ImageAdesivo}
                                alt="Imagem da Taça de Cerveja"
                                className="image-css h-full w-full"
                            />
                        </CardHeader>
                        <CardBody>
                            <div className="mb-2 flex items-center justify-between">
                                <Typography color="blue-gray" className="font-mediu">
                                    Cartela de adesivo
                                </Typography>

                                <Typography color="blue-gray" className="font-mediu">
                                    R$5,00
                                </Typography>
                            </div>
                        </CardBody>
                        <CardFooter className="pt-0">

                            <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                                <Button
                                    ripple={false}
                                    fullWidth={true}
                                    className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                >
                                    COMPRAR
                                </Button>
                            </a>

                        </CardFooter>
                    </Card>

                    <Card className="containerCard w-96 mt-8 mb-8" id="carta">
                        <CardHeader shadow={false} floated={false} className="h-96">
                            <img
                                src={ImageCarta}
                                alt="Imagem da Taça de Cerveja"
                                className="h-full w-full "
                            />
                        </CardHeader>
                        <CardBody>
                            <div className="mb-2 flex items-center justify-between">
                                <Typography color="blue-gray" className="font-mediu">
                                    Carta com passagem e <br /> ticket nightbus
                                </Typography>

                                <Typography color="blue-gray" className="font-mediu">
                                    R$30,00
                                </Typography>
                            </div>
                        </CardBody>
                        <CardFooter className="pt-0">
                            <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                                <Button
                                    ripple={false}
                                    fullWidth={true}
                                    className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                >
                                    COMPRAR
                                </Button>
                            </a>

                            <br />
                        </CardFooter>
                    </Card>
                </div>

                <div className="flex flex-col lg:flex-row">
                    <Card className="containerCard w-96 mt-8 mb-8" id="mapa">
                        <CardHeader shadow={false} floated={false} className="h-96">
                            <img
                                src={ImageMapa}
                                alt="Imagem da Taça de Cerveja"
                                className="image-css h-full w-full"
                            />
                        </CardHeader>
                        <CardBody>
                            <div className="mb-2 flex items-center justify-between">
                                <Typography color="blue-gray" className="font-mediu">
                                    Mapa do maroto
                                </Typography>

                                <Typography color="blue-gray" className="font-mediu">
                                    R$25,00
                                </Typography>
                            </div>
                        </CardBody>
                        <CardFooter className="pt-0">

                            <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                                <Button
                                    ripple={false}
                                    fullWidth={true}
                                    className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                >
                                    COMPRAR
                                </Button>
                            </a>

                        </CardFooter>
                    </Card>

                    <Card className="containerCard w-96 mt-8 mb-8" id="marca">
                        <CardHeader shadow={false} floated={false} className="h-96">
                            <img
                                src={ImageMarca}
                                alt="Imagem da Taça de Cerveja"
                                className="h-full w-full "
                            />
                        </CardHeader>
                        <CardBody>
                            <div className="mb-2 flex items-center justify-between">
                                <Typography color="blue-gray" className="font-mediu">
                                    Marca página
                                </Typography>

                                <Typography color="blue-gray" className="font-mediu">
                                    R$7,00
                                </Typography>
                            </div>
                        </CardBody>
                        <CardFooter className="pt-0">
                            <a href="https://api.whatsapp.com/message/C23HIMSSSYN2I1?autoload=1&app_absent=0" target='blank'>
                                <Button
                                    ripple={false}
                                    fullWidth={true}
                                    className="botao_compra text-white opacity-75 shadow-none hover:opacity-100 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                >
                                    COMPRAR
                                </Button>
                            </a>

                            <br />
                        </CardFooter>
                    </Card>
                </div>
            </div>
        </div>
    );
}