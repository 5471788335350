import Rotas from "./routes";
import "./style.app.css"

export default function App() {
  return (
    <div className="containerApp">
      <Rotas />
    </div>
  );
}
